<template>
    <div class="chance">
      <v-toolbar>
        <router-link to="/">
          <v-btn
            icon
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </router-link>
        <v-toolbar-title>Назад</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-toolbar-title class="mr-lg-8 mr-md-6 mr-sm-4">ДОСРОЧНЫЙ ВОЗВРАТ ПРАВ</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>
      <div class="container">
        <div class="text-h5 mt-10">ДОСРОЧНЫЙ ВОЗВРАТ ПРАВ</div>
        <div style="font-size: 15px">
          <p>Досрочный возврат прав в последнее время стал одним из самых популярных инструментов для автовладельцев, которые проиграли все суды или просто изначально смирились.</p>
          <p>Через половину срока возникает вопрос: а можно ли вернуть права досрочно?

            У тех, кто отходил все время пешком вопрос другой: а можно ли как то не сдавать экзамен или упростить этот процесс?</p>
          <p>Досрочный возврат водительского удостоверения возможен в порядке надзора в Областном суде (Верховном суде республики) и Верховном Суде Российской Федерации, а также в районном суде в случае если был пропущен срок на обжалование путем восстановления срока.</p>
        </div>
        <div class="text-h5 mt-5">Законодательство</div>
        <div style="font-size: 15px">
          <p>Если с момента вынесения постановления о лишении права управления прошло не более 10 дней у нас есть возможность прохождения обычной процедуры возврата водительского удостоверения путем осуществления защиты прав автовладельца. Помните, что ни ходатайство в суд, ни ходатайства с места работы, ни положительные характеристики не позволяют осуществить возврат водительских прав досрочно. Самой же юридической нормы и юридического термина «досрочный возврат прав» в  нет, поэтому многие автолюбители, которые были лишены прав в суде первой инстанции даже не подразумевают, что такой возврат возможен. Досрочный возврат водительского удостоверения возможен исключительно на основании рассмотрения дела в порядке надзора, чем и занимаются наши автоюристы.</p>
          <br><p>Для прохождения данной процедуры необходимо осуществить определенную юридическую подготовку.</p>
        </div>
        <div class="text-h5 mt-5">Что необходимо знать</div>
        <div style="font-size: 15px" class="mb-10">
          <p>Большинство случаев требуют подачи надзорной жалобы при которой производится истребование судом материалов дела (если необходима дополнительная проверка) и вынесение соответствующего решения, что и позволяет вернуть права досрочно при любом нарушении. Работа путем подачи указанной жалобы не предусматривает присутствие лица, привлеченного к административной ответственности и его защитника при рассмотрении дела. Это действие не требует от Вас ничего кроме предоставления всех имеющихся документов что очень удобно. А если таких документов нет – за Вас их получит автоюрист или расскажет как это сделать Вам.</p>
          <br><p>Мы самостоятельно обращаемся в том числе в Верховный Суд Российской Федерации в Москве и возвращаем права.</p>
        </div>
      </div>
      <div style="background-color: #74AC48; padding: 40px 0" id="ya1">
        <div class="container pr-7 pr-lg-0 px-sm-8 pr-sm-11">
          <div style="font-size: 24px!important;color: white" class="title mb-5 mb-lg-8 mb-xl-12">
            <span style="color: #ffed00">Бесплатная консультация</span> по возврату прав
          </div>
          <v-form v-model="valid">
            <v-row>
              <v-col
                cols="12"
                md="3"
                class="pa-sm-0 px-md-1 pa-0 px-2"
              >
                <v-text-field
                  v-model="firstName"
                  :rules="nameRules"
                  :counter="10"
                  label="Имя"
                  solo
                  clearable
                  required
                  outlined
                />
              </v-col>

              <v-col
                cols="12"
                md="3"
                class="pa-sm-0 px-md-1 pa-0 px-2"
              >
                <v-text-field
                  v-model="phone"
                  :rules="phoneRules"
                  :counter="11"
                  label="Телефон"
                  solo
                  clearable
                  required
                  outlined
                />
              </v-col>

              <v-col
                cols="12"
                md="3"
                class="pa-sm-0 px-md-1 pa-0 px-2"
              >
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail"
                  solo
                  clearable
                  required
                  outlined
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
                class="pa-sm-0 px-md-1 pa-0 px-2"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#404040"
                      style="color: #FFED00"
                      height="56"
                      width="100%"
                      @click="valid?send():false"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon v-if="statusSend">fas fa-circle-notch fa-spin</v-icon>
                      {{statusSend? '' : 'Получить'}}
                    </v-btn>
                  </template>
                  <span>{{!valid ? 'Заполните данные' : 'Отправить'}}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'chance',
  data () {
    return {
      valid: false,
      statusSend: false,
      firstName: '',
      phone: '',
      nameRules: [
        v => !!v || 'Введите имя',
        v => (v && v.length <= 10) || 'Имя не должно быть длиннее 10 символов'
      ],
      phoneRules: [
        value => !!value || 'Обязательное поле',
        value => (Boolean(Number(value))) || 'Введите только цифры',
        value => (value && value.length >= 11) || 'Не менее 11 цифр',
        value => (value && value.length <= 11) || 'Не более 11 цифр'
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail обязательное поле',
        v => /.+@.+/.test(v) || 'E-mail введен не правильно'
      ]
    }
  },
  methods: {
    send () {
      if (this.valid) {
        this.statusSend = true
        // const formData = new FormData()
        // formData.append('crm', '11')
        // formData.append('pipe', '22')
        // formData.append('contact[name]', this.firstName)
        // formData.append('contact[199]', this.phone)
        // formData.append('contact[200]', this.email)
        // formData.append('lead[214]', 'avtouristhelp.ru')
        // formData.append('note', 'Заявка с avtouristhelp.ru')
        //
        // axios.post('https://urist.v-avtoservice.com/api/import-lead',
        //   formData,
        //   {
        //     headers: {
        //       'Content-Type': 'multipart/form-data'
        //     }
        //   }
        // ).then(res => {
        //   this.dialog = true
        //   this.statusSend = false
        //   this.$router.push({ name: 'Thanks' })
        // })
        axios.post('https://technik-crm.ru:5000/lead/import-lead', {
          name: this.firstName,
          email: this.email,
          phone: this.phone,
          additional: '',
          website: 'avtouristhelp.ru',
          generate: 'avtouristhelp.ru',
          city: '',
          note: 'Заявка с avtouristhelp.ru'
        }).then(res => {
          this.dialog = true
          this.statusSend = false
          this.$router.push({ name: 'Thanks' })
        })
      } else return false
    }
  }
}
</script>

<style scoped>

</style>
